
import { Fragment, useEffect, useState}  from 'react';
import { useNavigate, Navigate, Outlet } from 'react-router-dom';
import { isMobile, isDesktop, isTablet } from 'react-device-detect';

import Container from '@mui/material/Container';
import { styled, alpha } from "@mui/material/styles";
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import AdbIcon from '@mui/icons-material/Adb';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';

import SimpleButton from '../../components/button/button.component';
import { useAuthState } from "../../contexts/user.context.component";

import { signOutUser } from '../../utils/firebase/firebase.utils';
import { getSessionValue, removeSessionValue } from '../../utils/helper.utils';

const StyledSearch = styled("div")(({ theme }) => ({
  position: "relative",
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.20),
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.40),
  },
  marginRight: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
  width: "95%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch"
    }
  }
}));

const search = (
  <StyledSearch>
    <SearchIconWrapper>
      <SearchIcon />
    </SearchIconWrapper>
    <StyledInputBase
      placeholder="search"
      inputProps={{ "aria-label": "search" }}
    />
  </StyledSearch>
);

const Navigation = () => {

  const { currentUser, setDeviceType, setCurrentUser, isAuthenticated } = useAuthState();

  const navigateTo = useNavigate();

  const homeMenu = [ { label:'HOME', url:'/'} ]; 
  
  const defaultMenus = [
    { label:'Why Us',  url:'/auth/why-us'},
    { label:'SIGN IN', url:'/auth/sign-in'}, 
    { label:'SIGN UP', url:'/auth/sign-up'},
  ];

  const protectedMenus = [
    { label:'ACCOUNT',  url:'/auth/account' },
    { label:'SIGN OUT', url:'/auth/sign-out' }, 
  ];  

  const getMenuOptions = () => {
    const menusNotLoggedIn = [ ...homeMenu, ...defaultMenus];    
    const menusLoggedIn = [ ...homeMenu, ...protectedMenus ];
  
    return ( currentUser || getSessionValue('token'))? 
      [ ...menusLoggedIn ] : [ ...menusNotLoggedIn ]; 
  };

  const [open, setOpen] = useState(false);

  useEffect( () => {
    (async () => {
        try{
            if (isMobile) {
              setDeviceType('mobile');
            }else if (isTablet) {
              setDeviceType('tablet');
            }else if (isDesktop) {
              setDeviceType('desktop');
            }
        }catch(error){
          console.error(error.message)
        }
    })();  
  }, [])

  const removeState = () => {
    setCurrentUser(null); 
    removeSessionValue('token');
    removeSessionValue('displayName')
  }

  const onMenuItemClick = async (url) => {
    setOpen(false);

    if (url == '/auth/sign-out'){
        removeState();
        await signOutUser(); 
        return <Navigate to='/auth/sign-in' replace />;
    }else{
        navigateTo(url);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  return (
    <Fragment>
      <AppBar position="sticky">
        <Container maxWidth="xl" disableGutters="true">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                }
              }}
            >
              <MenuIcon />
            </IconButton>
            
            <AdbIcon sx={{ display: { xs: 'none', md:'flex' }, mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              RAPP
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <AdbIcon sx={{ display: { xs: 'flex', md: 'none', justifyContent:'center' }, mr: 1 }} />                
              </Grid>
              <Grid item> 
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  RAPP
                </Typography>
              </Grid>
              <Box 
                sx={{              
                  flexGrow: 1, 
                  display: { xs: 'none', md: 'flex'}
                }}
              >
                {getMenuOptions().map((page) => (
                  <Button
                    key={page.label}
                    onClick={()=>onMenuItemClick(page.url)}
                    sx={{ my: 2, color: 'inherit', display: 'flex' }}
                  >
                    <Typography variant="button" display="block">
                      {page.label}
                    </Typography>
                  </Button>
                ))}
              </Box>
              <Box    
                sx={{
                  display: {
                    xs: "none",
                    md: "block"
                  }
                }}
              >
                {search}
              </Box>
            </Grid>

            <Drawer
              anchor="left"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
            >
              <Box
                sx={{
                  p: 2,
                  height: 1,
                }}
              >
                <IconButton sx={{ mb: 1, color:"primary.main" }}>
                  <CloseIcon onClick={toggleDrawer(false)} />
                </IconButton>

                <Divider sx={{ mb: 1 }} />
                <Box sx={{ mb: 1 }}>
                  <ListItemButton onClick={()=>onMenuItemClick('/')}>
                    <ListItemIcon>
                      <HomeIcon sx={{ color: "primary.main" }} />
                    </ListItemIcon>
                    <ListItemText primary="Home" sx={{ color: "text.primary" }} />
                  </ListItemButton>

                {(isAuthenticated || sessionStorage.getItem('token')) && (
                  <Fragment>
                    <ListItemButton onClick={()=>onMenuItemClick('/auth/account')}>
                      <ListItemIcon>
                        <PersonIcon sx={{ color: "primary.main" }} />
                      </ListItemIcon>
                      <ListItemText primary="My Account" sx={{ color: "text.primary" }} />
                    </ListItemButton>

                    <ListItemButton onClick={()=>onMenuItemClick('/')}>
                      <ListItemIcon>
                        <NotificationsIcon sx={{ color: "primary.main" }} />
                      </ListItemIcon>
                      <ListItemText primary="Notifications" sx={{ color: "text.primary" }} />
                    </ListItemButton>
                  </Fragment>
                )}
                </Box>

                {search}

                {(isAuthenticated || sessionStorage.getItem('token'))?
                (
                  <Box
                    sx={{
                      m:1,
                      position: "absolute",  
                      bottom:'5%',
                      width:'85%',
                    }}
                  > 
                    <Fragment>
                      <ListItemButton onClick={()=>onMenuItemClick('/auth/sign-out')}>
                        <ListItemIcon>
                          <LogoutIcon sx={{ color: "primary.main" }} />
                        </ListItemIcon>
                        <ListItemText primary="Sign out" sx={{ color: "text.primary" }} />
                      </ListItemButton>
                    </Fragment>
                  </Box>

                ):(
                  <Box
                    sx={{
                      display: "block",
                      justifyContent: "center",
                      position: "absolute",
                      bottom: "5%",
                      left: "20%",
                      transform: "translate(-10%, 0)"
                    }}
                  > 
                    <SimpleButton 
                      onClick={()=>onMenuItemClick('/auth/sign-in')} 
                      variant="contained" 
                      sx={{ m: 1, width: 0.9 }} 
                    >
                      SIGN IN
                    </SimpleButton>
                    <SimpleButton 
                      onClick={()=>onMenuItemClick('/auth/sign-up')} 
                      variant="outlined" 
                      sx={{ m: 1, width: 0.9 }}
                    >
                      SIGN UP
                    </SimpleButton>
                  </Box>
                )}
              </Box>
            </Drawer>
          </Toolbar>
        </Container>
      </AppBar>

      <Outlet />
    </Fragment>
  );
}

export default Navigation;