import { useContext } from 'react';

import GoogleButton from 'react-google-button';
import { UserContext } from '../../contexts/user.context.component';

import { 
    signInWithGooglePopup,
    createUserFromAuth,
} from '../../utils/firebase/firebase.utils';

import { getDateTime } from '../../utils/helper.utils';

const GoogleSignInButton = ({deviceType, label}) => {

    const { setLoginInProgress } = useContext(UserContext);
    
    const authenticateWithGoogle = async () => {
        return await signInWithGooglePopup();
    }

    const signInWithGoogle = async () => {
        try{
            setLoginInProgress(true);

            const { user } = await authenticateWithGoogle();
            const loginTimeStamp = getDateTime();
            const userDocRef = await createUserFromAuth(user, loginTimeStamp); 

            setLoginInProgress(false);
        }catch(error){
            console.error(error.message);
            setLoginInProgress(false);
        }
    };

    if (!label) {
        label = 'Sign in with Google';
    }
    return (

        <GoogleButton type="dark" label={label} onClick={signInWithGoogle} />
    );
}

export default GoogleSignInButton;