import { Fragment, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';

import { 
    Container,
    CardContent,
    Card,
    Box,
    Typography,
    Button
} from '@mui/material';

import SimpleButton from '../button/button.component';
import GoogleSignInButton from '../google-sign-in/google-sign-in-button.component';
import Input from '../input/input.component';
import Toast from '../toast/toast.component';
import { useAuthState } from '../../contexts/user.context.component';

import { 
    signOutUser,
    getUserFromAuth,
    sendVerificationEmail,
    signInUserWithEmailAndPassword
} from '../../utils/firebase/firebase.utils';

import { 
    isValidEmail, 
    isValidPassword, 
    getPasswordPolicy,
    setSessionValue
} from '../../utils/helper.utils';

import {  } from '../../utils/helper.utils';
import Loading from '../loading/loading.component';
import MessageBoxSlide from '../message-box/message-box.component';

const SignInForm = () => {

    const [ message, setMessage ] = useState({title: 'RAPP', body: 'All good', user: ''});

    const [ showMessage,  setShowMessage] = useState(false);

    const { setCurrentUser, deviceType, isAuthenticated, loginInProgress, setLoginInProgress } = useAuthState();

    const defaultFormValues = { email: '', password: '' };
    const clearError = { error: false, helperText: '' };

    const PASSWORD_POLICY = getPasswordPolicy();
    const EMAIL_ERROR_MSG = 'Enter a valid email';

    const [ formFields, setFormFieldValues ] = useState(defaultFormValues);
    const { email, password } = formFields;

    const [ emailError, setEmailError ] = useState(clearError);
    const [ passwordError, setPasswordError ] = useState(clearError);

    const [ toastEnabled, setToastEnabled ] = useState(false);
    const [ errorInfo,  setErrorInfo  ] = useState({ severity: '', message: '' });
    const { severity, toastMessage } = errorInfo;

    const [ authError, setAuthError ] = useState(false);

    const handleClose = () => {
        setShowMessage(false);
    }

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        if (name === 'email') {
            if (!isValidEmail(value)){
                setEmailError({error:true, helperText: EMAIL_ERROR_MSG});
            }else{
                setEmailError(clearError);
            }
        }else if (name === 'password') {
            if (!isValidPassword(value)){
                setPasswordError({error:true, helperText: PASSWORD_POLICY});
            }else{
                setPasswordError(clearError);
            }
        }
        setFormFieldValues({ ...formFields, [name]: value });
    }

    const ifErrorsExist = () => {
        if ( !email || !password ){
            setErrorInfo({ 
                severity: 'error', 
                toastMessage: 'Please fill in all the required fields' 
            });
            displayToast();
            return true;

        } else if (
            emailError.error || 
            passwordError.error 
        ) return true;
        
        return false;
    }

    const resendVerifyEmail = async (user) => {

        TODO: // restrict rate of request here.
        try{
            // console.log("User to receive email is : " + user.email);
            await sendVerificationEmail(user);
            setShowMessage(false);
        }catch(error){
            console.log(error.message);
            setShowMessage(false);
            setErrorInfo({ 
                severity: 'error', 
                toastMessage: 'Please try after 10 minutes' 
            });
            displayToast();
        }
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();        

        if ( ifErrorsExist() ) return;

        try{
            const { user } = await signInUserWithEmailAndPassword(email, password);
            const { emailVerified } = user;

            if (!emailVerified) {
                setAuthError(true);

                await signOutUser();

                setMessage({
                    title: 'Oops! Email not verified',
                    body: 'You have not verified your email yet. Please follow the instructions in the email that we sent you.',
                    user: user
                });
                setShowMessage(true);
                setAuthError(false);
                return;
            }

            // if the execution reached here, then user has signed in successfully
            
            setLoginInProgress(true);

            const userObject = await getUserFromAuth(user);
            setSessionValue('token', 'true');
            setCurrentUser(userObject);

            setLoginInProgress(false);

        }catch(error){

            setLoginInProgress(false);
            if (error.code == 'auth/wrong-password' || error.code == 'auth/user-not-found'){
                setErrorInfo({ 
                    severity: 'error', 
                    toastMessage: 'Invalid email id or password' 
                });
                displayToast();
            }else{
                console.error(error.message);
            }
        }
    }

    const displayToast = () => {
        setToastEnabled(true);
        setTimeout(() => {
            setToastEnabled(false);
        }, 4000);
    }

    return (
        (!authError && !loginInProgress) && (isAuthenticated || sessionStorage.getItem('token'))?(
            <Navigate to='/auth/account' replace />
        ):(!authError && loginInProgress)?(
            <Loading />
        ):(
            <Container maxWidth='xl' disableGutters="true">
                <Box sx={{ flexGrow: 1, minWidth:200,  display: { xs: 'flex' }, justifyContent: 'center', mt:7, mb:7 }}>
                    <GoogleSignInButton deviceType={deviceType}/>
                </Box>

                <Box flexDirection={"column"} sx={{flexGrow: 1, display: { xs: 'flex' }, alignItems: 'center', mb:3 }}>
                    <Typography variant='h6'
                        sx={{
                            fontWeight: 400,
                            letterSpacing: '.1rem',
                            color: 'text.primary',
                            textDecoration: 'none'
                        }}
                    >
                        Or, sign in with your email
                    </Typography> 
                </Box>

                <Box
                    sx={{
                        flexGrow: 1, 
                        display: { xs: 'flex' }, 
                        justifyContent: 'center'
                    }}
                >
                    <Card variant='outlined' 
                        sx={{ 
                            maxWidth: ( deviceType === 'mobile' )? 360: 500,  
                            boxShadow: 10,
                            borderColor: '#fff',
                            background: 'linear-gradient(to right bottom, #505053, #000)'
                        }}>
                        <CardContent>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '33ch' }, 
                                flexGrow: 1, 
                                display: { xs: 'flex' }, 
                                justifyContent: 'center',
                            }}
                            noValidate
                            autoComplete='off' 
                        >
                            <form onSubmit={handleFormSubmit}>
                                <Input 
                                    id='email' 
                                    name='email' 
                                    label='Email *'                            
                                    value={email}
                                    variant='outlined'
                                    onChange={handleOnChange}
                                    helperText={emailError.helperText}
                                    error={emailError.error} 
                                />
                                <Input 
                                    type='password' 
                                    id='password' 
                                    name='password' 
                                    label='Password *' 
                                    
                                    value={password}
                                    variant='outlined'
                                    onChange={handleOnChange}
                                    helperText={passwordError.helperText}
                                    error={passwordError.error} 
                                />
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'end', mr:1, mb:2 }}>
                                    <Link to="/auth/forgot-password">
                                        <Typography noWrap variant="caption"
                                            sx={{
                                                fontWeight: 400,
                                                letterSpacing: '.1rem',
                                                textDecoration: 'none',
                                                color:'text.primary'
                                            }}
                                        >
                                            Forgot password?
                                        </Typography>
                                    </Link>
                                </Box>  
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:1 }}>
                                    <SimpleButton size='large' type='submit' buttonType='contained'>
                                        Login 
                                    </SimpleButton>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', mt:3 }}>
                                    <Link to='/auth/sign-up'>
                                        <Typography noWrap variant='caption'
                                            sx={{
                                                fontWeight: 400,
                                                letterSpacing: '.1rem',
                                                textDecoration: 'none',
                                                color:'text.primary'
                                            }}
                                        >
                                            Click here if you don't have an account
                                        </Typography>
                                    </Link>
                                </Box>  
                            </form>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                    {(toastEnabled) && (
                        <Toast 
                            severity={ severity } 
                            message={ toastMessage } 
                        />
                    )}
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                    <MessageBoxSlide 
                        title={ message.title } 
                        message={ message.body } 
                        open={ showMessage } 
                        actionButtons={
                            <Fragment>
                                <Button onClick={()=>resendVerifyEmail(message.user)}>Resend Email</Button>
                                <Button onClick={handleClose}>Close</Button>
                            </Fragment>
                        }
                        />
                </Box>
            </Container>
        )
    );
}

export default SignInForm;