import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    Container,
    Grid,
    Paper,
    Box,
    Typography,
    Fab,
} from '@mui/material';

import RateReviewIcon from '@mui/icons-material/RateReview';

import SimpleButton from '../../components/button/button.component';
import { useAuthState } from '../../contexts/user.context.component';
import { getSessionValue } from '../../utils/helper.utils';

import ReviewsCarousel from '../../components/rating/reviews-carousal.component';

import data from '../../assets/content/content.json';
import parse from 'html-react-parser';
import CategoryList from '../../components/categories/category-list.component';

const Home = ({theme}) => {

    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    };

    const { isAuthenticated, deviceType } = useAuthState();

    const navigateTo = useNavigate();

    const navigateToPage = () => {
        navigateTo('/auth/sign-up');
    }

    return (
        (!isAuthenticated && !getSessionValue('token'))?(
            <Container maxWidth="xl" disableGutters="true">
                <Grid container 
                    direction="column"
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginTop={1}
                >
                    <Grid item xs>
                        <Grid container
                            direction="row"
                            justifyContent={'center'}
                            alignItems={'center'}
                            marginTop={1}
                        >
                            <Grid item xs={12} md={6}>
                                <Grid container
                                    direction="column"
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    marginTop={1}
                                >
                                    <Grid item xs={12} md={12}
                                        sx={{
                                            mt:3,
                                        }}
                                    >
                                        <Typography variant='h4'
                                            align='center'
                                            sx={{
                                                fontWeight: 400,
                                                letterSpacing: '.1rem',
                                                color: 'text.primary',
                                                textDecoration: 'none',
                                                p:2,
                                            }}
                                        >
                                            { parse(data.content.pages.home.para1) }
                                        </Typography>      
                                    </Grid>

                                    <Grid item xs={12} md={12}
                                        sx={{
                                            m:2,
                                        }}
                                    >
                                        <Paper 
                                            elevation={15}
                                            sx={{ 
                                                m:2,
                                                p:2,
                                                background: 'linear-gradient(to right bottom, #ff9719, #faf8f7 )'
                                            }}
                                        >
                                            <Typography variant='h5'
                                                align='left'
                                                sx={{
                                                    fontWeight: 400,
                                                    letterSpacing: '.1rem',
                                                    color: 'text.contrast',
                                                    textDecoration: 'none',
                                                    p:3
                                                }}
                                            >
                                                { parse(data.content.pages.home.para2) }
                                            </Typography>
                                            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                                                <SimpleButton size='large' onClick={navigateToPage} buttonType='contained'>
                                                    JOIN TODAY 
                                                </SimpleButton>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>         
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container
                                    direction="column"
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    marginTop={1}
                                >
                                    <Grid item xs={12} md={12}
                                        sx={{
                                            m:2,
                                        }}
                                    >
                                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyItems: 'center' }}>
                                            <Typography variant='body'
                                                align='left'
                                                sx={{
                                                    fontWeight: 400,
                                                    letterSpacing: '.1rem',
                                                    color: 'text.primary',
                                                    textDecoration: 'none',
                                                    p:2
                                                }}
                                            >
                                                { parse(data.content.pages.home.para3 ) }
                                                  
                                            </Typography> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={12}
                                        sx={{
                                            m:2,
                                        }}
                                    >
                                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center' }}>
                                            <SimpleButton size='large' buttonType='outlined'>
                                                Share your experience 
                                            </SimpleButton>
                                        </Box>                               
                                    </Grid>
                                </Grid>
                            </Grid>     
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}
                        sx={{
                            m:2,
                        }}
                    >
                        <Grid container
                            direction="row"
                            justifyContent={'center'}
                            alignItems={'center'}
                            marginTop={1}
                        >   
                            <Grid item xs={12} md={12}>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyItems: 'center',width:'100%' }}>
                                    <Typography variant='body'
                                        align='left'
                                        sx={{
                                            fontWeight: 400,
                                            letterSpacing: '.1rem',
                                            color: 'text.primary',
                                            textDecoration: 'none',
                                            p:1,
                                            m:2, mb:5
                                        }}
                                    >
                                        { parse(data.content.pages.home.para4 ) }
                                        
                                    </Typography> 
                                </Box>  
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
                
                <CategoryList />
                <ReviewsCarousel />      
            </Container>
        ):(              
            <Fragment>
                <CategoryList />
                <ReviewsCarousel />   
                <Fab color="primary" aria-label="add" style={style}>
                    <RateReviewIcon />
                </Fab>
            </Fragment>
        )
    );
}

export default Home; 