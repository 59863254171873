import { Container, Grid, Box, Typography, Link } from "@mui/material";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";

export default function Footer() {
  return (
    <Box
        component="footer"
        sx={{
            backgroundColor: (theme) =>
            theme.palette.mode === "light"
            ? theme.palette.background.footer
            : theme.palette.background.footer,
            p:3,
            mt:5
        }}
    >
        <Container maxWidth="xl" disableGutters="true">
            <Grid container spacing={5}>
                <Grid item xs={12} >
                    <Typography
                        align="center"
                        sx={{
                            fontWeight: 400,
                            fontSize: 12,
                            color:'inherit',
                            letterSpacing: '.1rem',
                            textDecoration: 'none'
                        }}
                    >
                        This site is protected by reCAPTCHA and the Google{" "}
                        <Link 
                            href="https://policies.google.com/privacy" 
                            underline="always" 
                            target="_blank" 
                            rel="noopener" 
                            color="inherit"
                        >
                            {'Privacy Policy'}
                        </Link> and{" "} 
                        <Link 
                            href="https://policies.google.com/terms" 
                            underline="always" 
                            target="_blank" 
                            rel="noopener" 
                            color="inherit"
                        >
                            {'Terms of Service'}
                        </Link> apply.
                    </Typography>   
                </Grid>
                <Grid item xs={12} sm={6} md={4} align="center">
                    <Typography variant="subtitle1" color="text.primary" gutterBottom
                        sx={{ fontWeight: 400,}}>
                        About Us
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align='center'>
                        This is RAPP, the largest and the leading platform about merchants' products and services.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} align="center">
                    <Typography variant="subtitle1" color="text.primary" gutterBottom
                        sx={{ 
                            align: 'left',
                            fontWeight: 400,
                        }}
                    >
                        Contact Us
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        81 Main Street, Edinburgh, UK
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Email: info@datxi.com
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Phone: +44 123 123 1234
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} align="center">
                    <Box>
                        <Typography variant="subtitle1" color="text.primary" gutterBottom
                            sx={{ fontWeight: 400,}}>
                            Follow Us
                        </Typography>
                        
                        <Link href="https://www.facebook.com/" color="inherit">
                            <Facebook />
                        </Link>
                        <Link
                            href="https://www.instagram.com/"
                            color="inherit"
                            sx={{ pl: 1, pr: 1 }}
                        >
                            <Instagram />
                        </Link>
                        <Link href="https://www.twitter.com/" color="inherit">
                            <Twitter />
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={5}>
                <Typography variant="body2" color="text.primary" align="center"
                    sx={{ fontWeight: 400, mb:2}}>
                    {"Copyright © "}{new Date().getFullYear()}
                    {"."}{" "}
                        datxi{"."}
                    {" "}All rights reserved{"."}
                </Typography>
            </Box>
        </Container>
    </Box>
  );
}