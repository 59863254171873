import { Fragment } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Container, Grid, Avatar, Box, Rating, Card, Typography } from "@mui/material"

import { useAuthState } from "../../contexts/user.context.component";

const items = [
    {
        id:'1',
        reviewer:'Smith J.',
        merchant:'XYZ Company'
    },
    {
        id:'1',
        reviewer:'Carol A.',
        merchant:'PQR Company'
    },
    {
        id:'1',
        reviewer:'Stephany L.',
        merchant:'K&Y Trading'
    },
    {
        id:'1',
        reviewer:'Rob M.',
        merchant:'AB Electronics'
    },
    {
        id:'1',
        reviewer:'Alex B.',
        merchant:'KY Global'
    },
];

const ReviewsCarousel = () => {

    const { deviceType } = useAuthState();
    
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        extraLargeDesktop: {
            breakpoint: { max: 3000, min: 1201 },
            items: 4
        },
        desktop: {
          breakpoint: { max: 1200, min: 901 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 900, min: 601 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1
        }
    };


    const getContent = () => {
        return  items.map((item, index) => {
            return ( <Card 
                elevation={5}
                sx={{
                    borderRadius:0,
                    background: 'linear-gradient(to right bottom, #f7f5f5, #ffffff)',
                    m:0.5,
                    p:2,
                }}
            >
                <Grid container 
                    direction="column"
                    justifyContent={'left'}
                >
                    <Grid item xs key={index}>
                        {/* Row 1*/}
                        <Grid container 
                            direction="row"
                        >
                            <Grid item
                                sx={{
                                    mr:2
                                }}
                            >
                                <Avatar sx={{  width: 32, height: 32, bgcolor: '#f3dede' }}>N</Avatar>
                            </Grid>
                            <Grid item>
                                <Grid container 
                                    direction="column"
                                >
                                    <Grid item>
                                        <Typography variant='h6'
                                            align="left"
                                            sx={{
                                                fontSize:14,
                                                color:'text.contrast',
                                            }}
                                        >
                                            {item.merchant}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h6'
                                            align="left"
                                            sx={{
                                                fontSize:11,
                                                color:'text.contrast',
                                            }}
                                        >
                                            {item.reviewer}
                                        </Typography> 
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/* Row 2*/}
                        <Grid container 
                            direction="column"
                        >
                            <Grid item
                                sx={{
                                    mt:1,
                                }}
                            >
                                <Typography variant='caption'
                                    align="left"
                                    sx={{
                                        fontSize:12,
                                        color:'text.contrast',
                                    }}
                                >
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.{" "}
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s

                                </Typography>
                            </Grid>
                            <Grid item xs
                                sx={{
                                    mt:1
                                }}
                            >
                                <Box>
                                    <Rating name="size-small" size="small" value={Math.floor(Math.random() * 5) + 1} readOnly />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            )
        })
    };

    return (
        <Container align="left"  maxWidth="xl"
            sx={{
                alignItems:'center'
            }}
        >
            <Typography variant='h5'
                align="center"
                sx={{
                    m:2, mt:7,
                    color:'text.secondary',
                }}
            >
                Recent Reviews
            </Typography>
            <Carousel responsive={responsive} 
                swipeable={true}
                draggable={false}
                showDots={false}
                ssr={true}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all 0.5"
                transitionDuration={1000}
                containerClass="carousel-container"
                arrows={false}
                // removeArrowOnDeviceType={["mobile"]}
                deviceType={deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >  
                
            { getContent() }

            </Carousel>
        </Container>
    );
}

export default ReviewsCarousel;
