import { Box, TextField } from '@mui/material';

const Input = ({id, label, size='small', variant='filled', ...otherProps}) => {
    return (
        <Box>
            <TextField    
                fullWidth 
                size={size} 
                id={id} 
                label={label} 
                variant={variant} 
                {...otherProps} 
            />
        </Box>
    );
}

export default Input;