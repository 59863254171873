// This is a utility file for all other helper functions.

export const getDateTime = () => {
    const dt = new Date();
    return dt.toISOString();
};

export const isValidEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

export const getPasswordPolicy = () => {
    return "Minimum 8 characters, one letter and a number"
}

export const isValidPassword = (password) => {

    // Po;icy (1)
    //// Minimum eight characters, at least one letter and one number
    return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);

    // Policy (2)
    //// Minimum eight characters, at least one letter, one number and one special character:
    // return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.text(password)
}


export const setSessionValue = (key, value, from='na') => {
    window.sessionStorage.setItem(key, value);
}

export const getSessionValue = (key, from='na') => {
    return window.sessionStorage.getItem(key);
}

export const removeSessionValue = (key) => {
    window.sessionStorage.removeItem(key);
}

export const useResponsive = () => {
    return {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3001 },
            items: 6
        },
        extraLargeDesktop: {
            breakpoint: { max: 3000, min: 1201 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 1200, min: 901 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 900, min: 601 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 2
        }
    }
};