import { Fragment, forwardRef } from 'react';

import { 
  Dialog, DialogActions, 
  DialogContent,
  DialogTitle,
  DialogContentText,
  Slide 
} from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MessageBoxSlide = ({open, actionButtons, title='RAPP', message='All good'}) => {

  return (
    <Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {actionButtons}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default MessageBoxSlide;