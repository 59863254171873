import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import { UserProvider } from './contexts/user.context.component';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Provides a consistent baseline styling
import SimpleTheme from './utils/theme/theme.utils';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Footer from './components/footer/footer.component';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECATCHA_KEY}>
          <ThemeProvider theme={SimpleTheme}>
            <CssBaseline />
              <App />
          </ThemeProvider>
        </GoogleReCaptchaProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
