import { paste } from '@testing-library/user-event/dist/paste';
import { initializeApp } from 'firebase/app';
import { applyActionCode, getRedirectResult } from 'firebase/auth';

import { 
    getAuth,
    signOut,
    signInWithRedirect, 
    signInWithPopup, 
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    GoogleAuthProvider,
    onAuthStateChanged
} from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";
import { setSessionValue } from '../helper.utils';

import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    updateDoc
} from 'firebase/firestore';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context.component';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopup = async () => {
    const result = await signInWithPopup(auth, provider);

    return result;
};

export const signInWithGoogleRedirect = async () => {
    const result = await signInWithRedirect(auth, provider);
    return await getRedirectResult(auth);
    // return result;
};

export const signInUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return  await signInWithEmailAndPassword(auth, email, password);
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);

};

export const sendVerificationEmail = async (user) => {
    if (!user) return;

    return await sendEmailVerification(user);
}

export const signOutUser = async () => {
    try{
        return await signOut(auth);

    }catch (error){
        console.error('error while sign out\n' + error.message);
    }
}

export const onUserAuthStateChanged = async (callback) => {

    return await onAuthStateChanged(auth, callback);
}

// Cloud Firestore
export const db = getFirestore();

export const getUserFromAuth = async (userAuth) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    try{
        const userRef = await getDoc(userDocRef);
        if (userRef.exists()){
            return userRef.data(); 
        }

        return {};
    }catch(error){
        console.log(error.message);
    };
};

export const createUserFromAuth = async (userAuth, loginTimeStamp, additionalData = {}) => {
    const userDocRef = doc(db, 'users', userAuth.uid);
    try{
        const userRef = await getDoc(userDocRef);

        if (userRef.exists()){
            try{
                console.log('updating user document..');
                await updateDoc(userDocRef, {lastLogin: loginTimeStamp});
            }catch (error){
                console.error('error updating the user document\n', error.message);
            } 
        }else{
            try{
                console.log('inserting user document..');
                const data = {
                    displayName: userAuth.displayName,
                    email: userAuth.email,
                    emailVerified: userAuth.emailVerified,
                    firstLogin: loginTimeStamp,
                    ...additionalData
                } 
                await setDoc(userDocRef, data);
            }catch (error){
                console.error('error creating the user\n', error.message);
            } 
        }
    }catch (error){
        console.error('error retrieving user document reference\n', error.message);
    }
    return userDocRef;
};


export const confirmEmailVerified = async (oobCode) => {
  if (!oobCode) return false;

  try {
    await applyActionCode(auth, oobCode);
  } catch (error) {
    console.log(error.message);
    return true;
  }
  
  return true;
};

export const sendResetPasswodEmail = async (email) => {

    if (!email) return;

    return await sendPasswordResetEmail(auth, email);
}