import { Box, Typography, Alert } from '@mui/material';

const Toast = ({message, severity="success"}) => {
    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', mb:1 }}>
            <Alert severity={severity}>
                <Typography variant="body" 
                    sx={{ 
                        fontWeight: 400,
                        letterSpacing: '.1rem',
                        color: 'inherit',
                        textDecoration: 'none'
                    }}
                >
                    {message}
                </Typography>
            </Alert>
        </Box>
    )
}

export default Toast;
