import { Button } from '@mui/material';

const SimpleButton = ({children, buttonType, color='primary', ...otherProps}) => {

    const BUTTON_TYPE = {
        text: 'text',
        contained: 'contained',
        outlined: 'outlined'
    }

    return (
        <Button 
            size='large'
            color={color}
            variant={`${BUTTON_TYPE[buttonType]}`} 
            sx={{
                minWidth: 150
            }}
            { ...otherProps }
        >
            { children }
        </Button>        
    );
}

export default SimpleButton;