import { createTheme } from '@mui/material/styles';

const SimpleTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ff9719',
    },
    secondary: {
      main: '#ff7637',
    },
    background: {
      default: '#3a3333',
      paper: '#627478',
      footer: '#000'
    },
    text:{
      primary: '#fff',
      secondary: '#ff9719',
      contrast: '#000'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: "#151616",
          backgroundColor: "#ff9719"
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: 'linear-gradient(to right bottom, #505053, #000)',
          color: "#e37f05",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: { 
          border: '1px solid',
          color:'#fff',
          borderColor: '#fff',
          backgroundColor: '#ff7637',
          "&:hover": {
            backgroundColor: '#151616',
            borderColor: '#fff',
            border: '1px solid',
          },
        },
        contained: { 
          border: '1px solid',
          color:'#fff',
          borderColor: '#fff',
          backgroundColor: '#151616',
          "&:hover": {
            backgroundColor: '#ff7637',
            borderColor: '#fff',
            border: '1px solid',
          },
        }
      }
    }
  },
  shape: {
    borderRadius: 17,
  }, 
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default SimpleTheme;