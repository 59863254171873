import * as React from 'react';
import { Link } from 'react-router-dom';

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel';

import { Container, Grid, Card, Typography } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import AcUnitIcon from '@mui/icons-material/AcUnit';

import { useAuthState } from '../../contexts/user.context.component';
import data from '../../assets/content/content.json';
import useMediaQuery from '@mui/material/useMediaQuery';

const CategoryList = () => {
    const { deviceType } = useAuthState();
    const theme = useTheme();
    const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesMedium = useMediaQuery(theme.breakpoints.up('md'));
    const matchesLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesXLarge = useMediaQuery(theme.breakpoints.up('xl'));

    const getColumnSize = () => {
        return 4;
    }
        
    const categories = data.content.products.categories;
    const categoryLength = categories.length;

    const getElements = () => {

        const content = [];
        const columnSize = getColumnSize();

        for (let i = 0; i < categoryLength; i += columnSize) {

            const chunk = categories.slice(i, i + columnSize);
            console.log(chunk);
            
            const elements = [];
                
            chunk.map( (category, index) => {
                console.log('merchant: ' + category.merchant);

                elements.push(
                    <Grid item xs={12} key={index}
                        sx={{
                            m:0.4
                        }}
                    >
                        <Link to='/auth/sign-in'>
                            <Card
                                sx={{
                                    borderRadius:0,
                                    background: 'linear-gradient(to right bottom, #ff7637, #ff9719 )'
                                }}
                            > 
                                <Grid container 
                                    direction={'row'} 
                                    alignItems={'center'}
                                    height='50px'
                                >
                                    <Grid item xs={4} align='center' justifyContent={'center'}
                                        sx={{
                                            pt:0.5
                                        }}>
                                        <AcUnitIcon 
                                            sx={{ 
                                                fontSize: 30
                                            }} 
                                        />
                                    </Grid>
                                    <Grid item xs={8} align='left'>
                                        <Typography  
                                            sx={{
                                                p:1,
                                                fontSize: 15,
                                                fontWeight: 500,
                                                color:'text.contrast'
                                            }}
                                        >                   
                                            { category.merchant }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>        
                        </Link>
                    </Grid>  
                )
            })

            content.push(
                <Grid container
                    direction="column"
                    marginBottom={4}
                    
                >
                    {elements}
                </Grid>
            );
        }
        
        return ( content );
    }

    const getSlideColumns = () => {
        if ( matchesXLarge ) {
            return 4;
        }else if ( matchesLarge ) {
            return 3;
        } else if ( matchesMedium ) {
            return 3;
        } else if ( matchesSmall ) {
            return 2;
        } else {
            return 1;
        }
    }

    return (
        <Container maxWidth="lg">
            <Typography variant='h5'
                align="center"
                sx={{
                    mt:2, mb:2,
                    color:'text.secondary',
                }}
            >
                Review Categories
            </Typography>

            <Carousel  
                swipeable={true}
                centerMode={true}
                showIndicators={true}
                centerSlidePercentage={ 100 / getSlideColumns() }
                emulateTouch={true}
                selectedItem={0}
                showStatus={false}
                width={'100%'}
            >  
                { getElements() }
            </Carousel>
        </Container>
    );
}

export default CategoryList;