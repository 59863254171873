import { Navigate } from 'react-router-dom';
import { Fragment } from 'react';

import { Box, Typography, Alert, CircularProgress, Backdrop } from '@mui/material';

import { useAuthState } from '../../contexts/user.context.component';

const Loading = ({
    message="Please wait while authentication is in progress.", 
    severity="info"}) => {

    const { isAuthenticated, loginInProgress } = useAuthState();
    const open = true;
    
    return (
        (loginInProgress)?(
            <Fragment>
                <Backdrop
                    sx={{ 
                        color: '#fff', 
                        zIndex: (theme) => theme.zIndex.drawer + 1 
                    }}
                    open={open}
                >
                    <Box 
                        sx={{ 
                            flexGrow: 1, 
                            display: { xs: 'flex' }, 
                            justifyContent: 'center', 
                            m:10 
                        }}
                    >
                        <CircularProgress color="inherit"/>
                    </Box>
                </Backdrop>
                <Box 
                    sx={{ 
                        flexGrow: 1, 
                        display: { xs: 'flex' }, 
                        justifyContent: 'center',  
                        mt:10, mr:3, ml:3 
                    }}
                >
                    <Alert severity={severity}>
                        <Typography variant="body" 
                            sx={{ 
                                fontWeight: 400,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            {message}
                        </Typography>
                    </Alert>    
                </Box>
            </Fragment>
        ):(isAuthenticated)?(
            <Navigate to='/auth/account' repalce />
        ):(
            <Navigate to='/auth/sign-in' replace />
        ) 
    );
}

export default Loading;