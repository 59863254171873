import { Navigate } from "react-router-dom";

import { Container, Box, Typography} from '@mui/material';

import { useAuthState } from "../../contexts/user.context.component";
import { getSessionValue, setSessionValue } from "../../utils/helper.utils";

const AccountView = () => {

    const { isAuthenticated, currentUser } = useAuthState();

    let displayName = '';

    if (currentUser){
        displayName = currentUser.displayName;
        if (displayName) {
            setSessionValue('displayName', displayName)
        }
    }

    return (
        (isAuthenticated || getSessionValue('token'))?(
            <Container maxWidth="xl" disableGutters="true">
                <Box flexDirection={"column"} sx={{ flexGrow: 1, display: { xs: 'flex' }, alignItems: 'center', mt:6, mb:5 }}>
                    <Typography variant="h6"
                    sx={{
                        fontWeight: 400,
                        letterSpacing: '.1rem',
                        color: 'inherit',
                        textDecoration: 'none'
                    }}
                    >
                        Hi {displayName || getSessionValue('displayName')},
                    </Typography>
                    <Typography variant="subtitle1"
                    sx={{
                        fontWeight: 400,
                        letterSpacing: '.1rem',
                        color: 'inherit',
                        textDecoration: 'none'
                    }}
                    >
                        Welcome, this is your account page
                    </Typography>
                </Box>
            </Container>
        ):(
            <Navigate to='/auth/sign-in' replace />
        )
    );
}

export default AccountView; 
