import { Routes, Route } from 'react-router-dom';

import Home from './routes/home/home.component';
import Navigation from './routes/navigation/navigation.component';
import AccountView from './components/account/account.component';

import './App.css';
import { Container } from '@mui/material';
import SignInForm from './components/sign-in/sign-in-form.component';
import SignUpForm from './components/sign-up/sign-up-form.component';

import ForgotPasswordView from './components/self-service/forgot-password.component';
import Footer from './components/footer/footer.component';

const App = () => {
  
  return (
    <div>
      <div className="main-container">
        <Routes>
          <Route path='/' element={<Navigation/>}>
            <Route index element={<Home />}/>
            <Route path='/auth/sign-in' element={<SignInForm />} />
            <Route path='/auth/sign-up' element={<SignUpForm />} />
            <Route path='/auth/account' element={<AccountView />} />
            {/* <Route path='/auth/action' element={<ActionsView />} /> */}
            <Route path='/auth/forgot-password' element={<ForgotPasswordView />} />
          </Route>

          <Route path="*" element={<p>Oops! There's nothing here, I'm afraid </p>} />
        </Routes>
      </div>
      <div className="footer-container">
        <Footer />

      </div>
      
    </div>
  )
};

export default App;
