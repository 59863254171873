import { useState } from 'react';
import { Link } from 'react-router-dom';

import { 
    Container,
    Card,
    CardContent,
    Box,
    Typography
} from '@mui/material';

import SimpleButton from '../button/button.component';
import GoogleSignInButton from '../google-sign-in/google-sign-in-button.component';
import Input from '../input/input.component';
import Toast from '../toast/toast.component';

import { removeSessionValue } from '../../utils/helper.utils';

import { 
    createAuthUserWithEmailAndPassword, 
    createUserFromAuth,
    sendVerificationEmail,
    signOutUser,
} from '../../utils/firebase/firebase.utils';

import { 
    getDateTime,
    isValidEmail, 
    isValidPassword, 
    getPasswordPolicy 
} from '../../utils/helper.utils';
import { useAuthState } from '../../contexts/user.context.component';

const SignUpForm = () => {

    const { deviceType, setCurrentUser } = useAuthState();

    const defaultFormValues = {
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
    };

    const clearError = { error: false, helperText: '' };

    const PASSWORD_POLICY = getPasswordPolicy();
    const MSG_EMAIL_ERROR = 'Enter a valid email';
    const MSG_SUCCESS_ACCOUNT_CREATION = 'Account creation successful. Please check your email.'

    const [ displayNameError, setDisplayNameError ] = useState(clearError);
    const [ emailError, setEmailError ] = useState(clearError);
    const [ passwordError, setPasswordError ] = useState(clearError);
    const [ confirmPasswordError, setConfirmPasswordError ] = useState(clearError);

    const [ formFields, setFormFieldValues ] = useState(defaultFormValues);
    const { displayName, email, password, confirmPassword } = formFields;

    const [ toastEnabled, setToastEnabled ] = useState(false);
    const [ errorInfo,  setErrorInfo  ] = useState({ severity: '', message: '' });
    const { severity, message } = errorInfo;

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        if (name === 'displayName') {
            if (!value){
                setDisplayNameError({error:true, helperText: 'Invalid display name'});
            }else{
                setDisplayNameError(clearError);
            }
        }else if (name === 'email') {
            if (!isValidEmail(value)){
                setEmailError({error:true, helperText: MSG_EMAIL_ERROR});
            }else{
                setEmailError(clearError);
            }
        }else if (name === 'password') {
            if (!isValidPassword(value)){
                setPasswordError({error:true, helperText: PASSWORD_POLICY});
            }else{
                setPasswordError(clearError);
            }
        }else if (name === 'confirmPassword') {
            if (!isValidPassword(value)){
                setConfirmPasswordError({error:true, helperText: PASSWORD_POLICY});
            }else{
                setConfirmPasswordError(clearError);
            }
        }
        setFormFieldValues({ ...formFields, [name]: value });
    }

    const ifErrorsExist = () => {
        if (
            !displayName ||
            !email || 
            !password ||
            !confirmPassword
        ){
            setErrorInfo({ 
                severity: 'error', 
                message: 'Fill in all the required fields' 
            });
            displayToast();
            return true;

        }else if (
            displayNameError.error || 
            emailError.error || 
            passwordError.error || 
            confirmPasswordError.error
        ) return true;
        
        return false;
    }

    const displayToast = () => {
        setToastEnabled(true);
        setTimeout(() => {
            setToastEnabled(false);
        }, 5000);
    }
    
    const removeState = () => {
        setCurrentUser(null); 
        removeSessionValue('token');
        removeSessionValue('displayName')
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if ( ifErrorsExist() ) return;
        
        if ( password !== confirmPassword){
            setErrorInfo({ 
                severity: 'error', 
                message: '"Password" and "Confirm password" do not match!' 
            });
            displayToast();
            return;
        }

        try{
            const response = await createAuthUserWithEmailAndPassword(email, password);
            if (response) {
                await signOutUser(); 
                removeState();
            }

            if (!response) return;            
            
            const { user } = response;
            const loginTimeStamp = getDateTime();
            const userDocRef = await createUserFromAuth(user, loginTimeStamp, { displayName: displayName });

            setFormFieldValues(defaultFormValues);
            await sendVerificationEmail(user);

            setErrorInfo({ 
                severity: 'success', 
                message: MSG_SUCCESS_ACCOUNT_CREATION 
            });
            displayToast();

        } catch(error){

            if (error.code == 'auth/email-already-in-use'){
                setErrorInfo({ 
                    severity: 'error', 
                    message: 'email id entered already exists!' 
                });
                displayToast();
            }else{
                console.error(error.message);
                setErrorInfo({ 
                    severity: 'error', 
                    message: error.message 
                });
                displayToast();
            }
        }
    }

    return (
        <Container maxWidth='xl' disableGutters="true">
            <Box sx={{ flexGrow: 1, minWidth:200,  display: { xs: 'flex' }, justifyContent: 'center', mt:7, mb:7 }}>
                <GoogleSignInButton label='Sign up with Google' deviceType={deviceType}/>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', mb:3 }}>
                <Typography variant='h6'
                    sx={{
                        fontWeight: 400,
                        letterSpacing: '.1rem',
                        color: 'text.primary',
                        textDecoration: 'none'
                    }}
                >
                    Or, register with your email
                </Typography> 
            </Box>
            <Box
                sx={{
                    flexGrow: 1, 
                    display: { xs: 'flex' }, 
                    justifyContent: 'center'
                }}
            >
                <Card variant='outlined' 
                    sx={{ 
                        maxWidth: ( deviceType === 'mobile' )? 360: 500,  
                        boxShadow: 10,
                        borderColor: '#fff',
                        background: 'linear-gradient(to right bottom, #505053, #000)'
                    }}>
                    <CardContent>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '33ch' }, 
                                flexGrow: 1, 
                                display: { xs: 'flex' }, 
                                justifyContent: 'center'
                            }}
                            noValidate
                            autoComplete='off' 
                        >
                            <form onSubmit={handleFormSubmit}>
                                <Input 
                                    id='displayName' 
                                    name='displayName' 
                                    label='Display Name *'
                                    value={displayName}
                                    variant='outlined'
                                    onChange={handleOnChange}
                                    helperText={displayNameError.helperText}
                                    error={displayNameError.error} 
                                />
                                <Input 
                                    id='email' 
                                    name='email' 
                                    label='Email *'
                                    value={email}
                                    variant='outlined'
                                    onChange={handleOnChange}
                                    helperText={emailError.helperText}
                                    error={emailError.error} 
                                />
                                <Input 
                                    type='password' 
                                    id='password' 
                                    name='password' 
                                    label='password *' 
                                    value={password}
                                    variant='outlined'
                                    onChange={handleOnChange}
                                    helperText={passwordError.helperText}
                                    error={passwordError.error} 
                                />
                                <Input 
                                    type='password' 
                                    id='confirmPassword' 
                                    name='confirmPassword' 
                                    label='Confirm Password *'
                                    value={confirmPassword}
                                    variant='outlined' 
                                    onChange={handleOnChange}
                                    helperText={confirmPasswordError.helperText}
                                    error={confirmPasswordError.error} 
                                />
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                                    <SimpleButton size='large' type='submit' buttonType='contained'>
                                        Create Account 
                                    </SimpleButton>
                                </Box>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', mt:3 }}>
                                    <Link to='/auth/sign-in'>
                                        <Typography noWrap variant='caption'
                                            sx={{
                                                fontWeight: 400,
                                                letterSpacing: '.1rem',
                                                textDecoration: 'none',
                                                color:'text.primary'
                                            }}
                                        >
                                            Click here if you already have an account
                                        </Typography>
                                    </Link>                         
                                </Box>
                            </form>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                {(toastEnabled) && (
                    <Toast 
                        severity={ severity } 
                        message={ message } 
                    />
                )}
            </Box>
        </Container>
    );
}

export default SignUpForm;