import { createContext, useContext, useEffect, useState } from "react";

import { onUserAuthStateChanged } from "../utils/firebase/firebase.utils";
import { setSessionValue } from "../utils/helper.utils";

export const UserContext = createContext({
    currentUser: null,
    setCurrentUser: () => null,
    deviceType: null,
    setDeviceType: () => null,
    loginInProgress: false,
    setLoginInProgress: () => null,
    isLoggedIn: false,
    setIsLoggedIn: () => null,
    authError: false,
    setAuthError: () => null
} );

export const UserProvider = ({children}) => {

    const [ currentUser, setCurrentUser ] = useState(null);
    const [ deviceType, setDeviceType ] = useState(null);
    const [ loginInProgress, setLoginInProgress,  ] = useState(false);
    const [ isLoggedIn, setIsLoggedIn ] = useState(false);
    const [ authError, setAuthError ] = useState(false);

    useEffect( () => {
        (async () => {
            try{
                const unsubscribe = onUserAuthStateChanged((user) => {
                    setCurrentUser(user);
                    if (user && user.emailVerified){
                        setSessionValue('token','true');
                    }
                });

                return unsubscribe;
            }catch(error){
                console.error(error.message);
            }
        })();
    }, [])

    const value =  { 
        currentUser, setCurrentUser, 
        deviceType, setDeviceType,
        loginInProgress, setLoginInProgress, 
        isLoggedIn, setIsLoggedIn,
        authError, setAuthError
    }; 
    
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useAuthState = () => {

    const authState = useContext(UserContext);
    return (
        { 
            ...authState,  
            isAuthenticated: (
                (authState.currentUser)? true: false
            )
        }
    );
}