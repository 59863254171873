import { Fragment, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';

import { 
    Container,
    Card,
    CardContent,
    Box,
    Typography,
    Button
} from '@mui/material';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import SimpleButton from '../button/button.component';
import Input from '../input/input.component';
import Toast from '../toast/toast.component';
import { useAuthState } from '../../contexts/user.context.component';

import { getSessionValue } from '../../utils/helper.utils';

import { 
    sendResetPasswodEmail,
} from '../../utils/firebase/firebase.utils';

import { 
    isValidEmail
} from '../../utils/helper.utils';

import {  } from '../../utils/helper.utils';
import MessageBoxSlide from '../message-box/message-box.component';
import { deviceType } from 'react-device-detect';

const ForgotPasswordView = () => {

    const RECPATCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
    const CLOUD_PROJECT_ID = process.env.REACT_APP_GOOGLE_CLOUD_PROJECT_ID;
    const CLOUD_API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY;
    const EMAIL_ERROR_MSG = 'Enter a valid email';

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [ message, setMessage ] = useState({title: 'RAPP', body: 'All good', user: ''});

    const [ showMessage,  setShowMessage] = useState(false);

    const { isAuthenticated } = useAuthState();

    const defaultFormValues = { email: '', password: '' };
    const clearError = { error: false, helperText: '' };

    const [ formFields, setFormFieldValues ] = useState(defaultFormValues);
    const { email } = formFields;

    const [ emailError, setEmailError ] = useState(clearError);

    const [ toastEnabled, setToastEnabled ] = useState(false);
    const [ errorInfo,  setErrorInfo  ] = useState({ severity: '', message: '' });
    const { severity, toastMessage } = errorInfo;

    const [ reCaptchaToken, setReCaptchaToken ] = useState('');

    const handleClose = () => {
        setShowMessage(false);
    }

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        if (name === 'email') {
            if (!isValidEmail(value)){
                setEmailError({error:true, helperText: EMAIL_ERROR_MSG});
            }else{
                setEmailError(clearError);
            }
        }
        setFormFieldValues({ ...formFields, [name]: value });
    }

    const ifErrorsExist = () => {
        if ( !email ){
            setErrorInfo({ 
                severity: 'error', 
                toastMessage: 'Please fill in all the required fields' 
            });
            displayToast();
            return true;
        } 
        
        return false;
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();        

        if ( ifErrorsExist() ) return;

        try{
            TODO: // restrict rate of request here.
            // if (!executeRecaptcha) {
            //     console.log('reCaptcha not available');
            //     return;
            // }
            // const token = await executeRecaptcha('act_reset_pwd');

            // if (!token){
            //     setReCaptchaToken('');
            //     console.log('No token retuened');

            //     setErrorInfo({ 
            //         severity: 'error', 
            //         toastMessage: 'Please try again later' 
            //     });
            //     displayToast();
            //     return;
            // }

            // setReCaptchaToken(token);

            await sendResetPasswodEmail(email);
            setErrorInfo({ 
                severity: 'info', 
                toastMessage: 'Please check you email. We sent you a link.' 
            });
            displayToast();

            // console.log('token is: ' + reCaptchaToken);

            /* assess the recaptcha token received
            send request to the backend to trigger validation
            E.g.
            const response = await fetch('https://be.datxi.com/api/validateCaptcha?token=<token>')
            */

            // if (response.success) {
            //     await sendResetPasswodEmail(email);
            //
            //     setErrorInfo({ 
            //         severity: 'info', 
            //         toastMessage: 'Please check you email. We sent you a link.' 
            //     });
            //     displayToast();
            // }else{
            //     setErrorInfo({ 
            //         severity: 'error', 
            //         toastMessage: 'Please contact RAPP support for assistance.' 
            //     });
            //     displayToast();
            // }
             

        }catch(error){
            if (error.code == 'auth/user-not-found'){
                setErrorInfo({ 
                    severity: 'error', 
                    toastMessage: 'Sorry, we don\'t recognise that email' 
                });
                displayToast();
            }
            console.error(error.message);
        }
    }

    const displayToast = () => {
        setToastEnabled(true);
        setTimeout(() => {
            setToastEnabled(false);
        }, 4000);
    }

  return (
    (!isAuthenticated && !getSessionValue('token'))?(
        <Container maxWidth="xl" disableGutters="true">
            <Box
                sx={{
                    '& .MuiTextField-root': { mt:1, minWidth: '33ch' }, 
                    flexGrow: 1, 
                    display: { xs: 'flex' }, 
                    justifyContent: 'center',
                    marginTop:10
                }}
                noValidate
                autoComplete="off" 
            >
                <Card variant="outlined" 
                    sx={{ 
                        maxWidth: ( deviceType === 'mobile' )? 360: 500, 
                        boxShadow: 10,
                        border: '1px solid',
                        borderColor: '#fff',
                        background: 'linear-gradient(to right bottom, #505053, #000)'
                    }}>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', mb:3, mt:3 }}>
                        <Typography variant='h6'
                            sx={{
                                fontWeight: 400,
                                letterSpacing: '.1rem',
                                color: 'text.primary',
                                textDecoration: 'none'
                            }}
                        >
                            Forgot your password
                        </Typography> 
                    </Box>
                    <CardContent>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:1 }}>
                            <Typography variant="subtitle1"
                                sx={{
                                    fontWeight: 200,
                                    letterSpacing: '.1rem',
                                    color: 'text.primary',
                                    textDecoration: 'none'
                                }}
                            >
                                Please enter the email address you've used to create your account so we can email you a link to reset your password.
                            </Typography> 
                        </Box>
                    
                        <form onSubmit={handleFormSubmit}>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2, mt: 4}}>
                                <Input 
                                    fullWidth 
                                    id='email' 
                                    name='email' 
                                    label="Email"
                                    variant='outlined'
                                    value={email}
                                    onChange={handleOnChange}
                                    helperText={emailError.helperText}
                                    error={emailError.error} 
                                />
                            </Box>
                            <Box sx={{ flexGrow: 1, minWidth:250, display: { xs: 'flex' }, justifyContent: 'center', m:1 }}>
                                <SimpleButton size='large' type='submit' buttonType='contained'>
                                    Request Password Reset 
                                </SimpleButton>
                            </Box>
                            
                        </form>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', mt:3 }}>
                            <Link to="/auth/sign-in">
                                <Typography noWrap variant="caption"
                                    sx={{
                                        fontWeight: 400,
                                        letterSpacing: '.1rem',
                                        textDecoration: 'none',
                                        color:'text.primary'
                                    }}
                                >
                                    Click here to sign in
                                </Typography>
                            </Link>                         
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            
            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                {(toastEnabled) && (
                    <Toast 
                        severity={ severity } 
                        message={ toastMessage } 
                    />
                )}
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', m:2 }}>
                <MessageBoxSlide 
                    title={ message.title } 
                    message={ message.body } 
                    open={ showMessage } 
                    actionButtons={
                        <Fragment>
                            <Button onClick={handleClose}>Close</Button>
                        </Fragment>
                    }
                />
            </Box>
        </Container>
    ):(
        <Navigate to='/auth/account' replace />
    )
  );
}

export default ForgotPasswordView;